<!--考生考场安排编辑  -->
<template>
  <div class="EditArrange">
    <!-- 上面部分 -->
    <div class="top">
      <span style="font-size: 16px; font-weight: bold;">考生考场安排编辑</span>
      <el-button class="el-btn" size="small" @click="goBack">
        返回
      </el-button>
    </div>
    <!-- 表单 -->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      id="form-1"
      class="form"
      inline
      label-width="150px"
    >
      <el-form-item label="安排编号">
        <el-input v-model="form.planNum" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="考场类型">
        <el-select v-model="form.roomType" placeholder="请选择">
          <el-option
            v-for="item in form.roomTypeChoose"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="安排日期">
        <el-date-picker
          value-format
          v-model="form.planDate"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="监考老师">
        <el-input v-model="form.Invigilator" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="A分组码">
        <el-input v-model="form.Acode" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="B分组码">
        <el-input v-model="form.Bcode" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="安排单状态">
        <el-select v-model="form.planState" placeholder="请选择">
          <el-option
            v-for="item in form.planStateChoose"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="等级">
        <el-select v-model="form.grade" placeholder="请选择">
          <el-option
            v-for="item in form.gradeOpt"
            :key="item.id"
            :label="item.level"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="场地地址">
        <el-input v-model="form.address" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="选择场地">
        <el-select
          v-model="form.field"
          filterable
          placeholder="请输入并选择场地"
        >
          <el-option
            v-for="item in form.fieldChoose"
            :key="item.id"
            :label="item.site_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构/学科">
        <el-select
          v-model="form.mechanism"
          filterable
          placeholder="请输入并机构/学科"
          v-if="form.type == 1"
        >
          <el-option
            v-for="item in form.mechanismOpt"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="form.course"
          placeholder="请选择学科"
          v-if="form.type == 2"
        >
          <el-option
            v-for="item in form.courseOpt"
            :key="item.id"
            :label="item.course_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考场类B">
        <!-- <el-input v-model="form.testRoomB" placeholder="请输入"></el-input> -->
        <el-select v-model="form.testRoomB" placeholder="请选择">
          <el-option
            v-for="item in form.testRoomBopt"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="科目" prop="subject">
                <el-select v-model="form.subject"  placeholder="请选择科目">
                    <el-option
                      v-for="item in form.subjectOpt"
                      :key="item.id"
                      :label="item.course_name"
                      :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item> -->
      <el-form-item label="考场人数">
        <el-input v-model="form.testpeople" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="起止时间">
        <el-time-picker
          value-format="HH:mm"
          :picker-options="{
            selectableRange: '00:00 - 23:00',
          }"
          is-range
          format="HH:mm"
          v-model="form.startStop"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="时长">
        <el-input v-model="form.duration" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="简要备注">
        <el-input
          type="textarea"
          rows="4"
          class="textArea-width"
          v-model="form.remarks"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <!-- 底部 提交与取消 -->
    <div slot="footer" class="dialog-footer">
      <!-- <el-button style="background-color: #29726C;color: #FFFFFF;" @click="addstu">添加学员</el-button> -->
      <el-button
        style="background-color: #18BC9C;color: #FFFFFF;"
        @click="submitForm('form')"
        >提 交</el-button
      >
      <el-button class="btn" @click="resetForm('form')">取 消</el-button>
    </div>
  </div>
</template>

<script>
import { createLogger } from "vuex";

export default {
  data() {
    return {
      form: {
        planNum: "", //安排编号
        roomType: "", //考场类型
        roomTypeChoose: [
          { label: "单科考场", value: 1 },
          { label: "综合考场", value: 2 },
        ], //考场类型选择
        planDate: "", //安排日期
        Invigilator: "", //监考老师
        Acode: "", //A分组码
        Bcode: "", //B分组码
        planState: "", //安排状态
        planStateChoose: [
          { label: "准备", value: 1 },
          { label: "考试", value: 2 },
          { label: "取消", value: 3 },
        ], //安排单状态选择
        grade: "", //等级
        gradeOpt: [], //等级数据选项
        address: "", //场地地址
        field: "", //选择场地
        fieldChoose: [], //选择场地选项
        mechanism: "", //机构
        mechanismOpt: [], //机构选项
        course: "", //学科
        courseOpt: [], //学科选项
        testRoomB: "", //考场类B
        testRoomBopt: [
          { label: "学校场地", value: 1 },
          { label: "校外考点", value: 2 },
        ],
        subject: "", //科目
        subjectOpt: [], //科目选项
        testpeople: "", //考场人数
        startStop: ["00:00", "23:00"], //起止时间
        duration: "", //时长
        remarks: "", //简要备注
        type: 1, //判断机构或者学科
      },
      rules: {
        subject: [{ required: true, message: "科目不能为空", trigger: "blur" }],
      },
      bool: false,
    };
  },
  created() {
    // 等级数据
    this.$request({
      url: "/api/level/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      // console.log("等级数据",res.data.list)
      if (res.code == 1) {
        this.form.gradeOpt = res.data.list;
      }
    });

    // 场地数据
    this.$request({
      url: "/api/schoolarea/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log("场地数据",res.data.list)
      if (res.code == 1) {
        this.form.fieldChoose = res.data.list;
      }
    });

    // 机构数据
    this.$request({
      url: "/api/mechanism/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log("选择机构",res.data.list)
      this.form.mechanismOpt = res.data.list;
    });

    //学科 科目数据
    this.$request({
      url: "/api/course/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      // console.log("科目数据",res.data.list)
      if (res.code == 1) {
        this.form.courseOpt = res.data.list;
        this.form.subjectOpt = res.data.list;
      }
    });

    // 详情渲染
    this.$request({
      url: "/api/examarrange/detail",
      method: "POST",
      data: {
        id: this.$route.query.id,
      },
    }).then((res) => {
      if (res.code == 1) {
        console.log(res);
        let form = this.form;
        let data = res.data;
        form.planNum = data.number; //安排编号
        form.roomType = data.exam_type; //考场类型
        form.planDate = data.arrange_date; //安排日期
        form.Invigilator = data.exam_teacher; //监考老师
        form.Acode = data.agroup; //A分组码
        form.Bcode = data.bgroup; //B分组码
        form.planState = data.status; //安排状态
        form.grade = data.level; //等级
        form.address = data.area_address; //场地地址
        form.field = data.area_id; //选择场地
        form.mechanism = data.org_id; //机构
        form.course = data.course_id; //学科
        form.testRoomB = data.area_type; //考场类B
        form.subject = data.course; //科目
        form.testpeople = data.num; //考场人数
        //   form.startStop[0]=data.exam_starttime//起时间
        //   form.startStop[1]=data.exam_endtime//止时间
        if (data.exam_starttime != "") {
          // form.startStop = [new Date(start), new Date(end)]; //止时间
          form.startStop = [data.exam_starttime, data.exam_endtime];
        }
        form.duration = data.exam_len; //时长
        form.remarks = data.remark; //简要备注
        form.type = data.type; //判断机构或者学科
      }
    });
  },
  methods: {
    goBack() {
      //返回
      this.$router.go(-1);
    },

    submitForm(form) {
      let dateTime = JSON.parse(JSON.stringify(this.form.startStop));
      //提交
      // this.form.fieldChoose.forEach(item=>{
      //     if(item.id==this.form.field){
      //        this.form.field = item.id
      //     }
      // })
      this.$refs[form].validate((valid) => {
        if (valid) {
          // alert('submit!');
          // console.log(this.form)
          if (this.bool) {
            return;
          }
          this.bool = true;
          let form = this.form;
          // console.log(this.form.roomType)
          let org_id = null;
          if (this.form.type == 1) {
            org_id = this.form.mechanism; //机构id
          } else {
            org_id = this.form.course; //学科id
          }
          this.$request({
            url: "/api/examarrange/save",
            method: "POST",
            data: {
              id: this.$route.query.id,
              number: form.planNum, //安排单号
              exam_type: form.roomType, //考场类型
              arrange_date: form.planDate, //安排日期
              exam_teacher: form.Invigilator, //监考老师
              agroup: form.Acode, //A分组码
              bgroup: form.Bcode, //B分组码
              status: form.planState, //安排单状态
              level: form.grade, //等级
              area_address: form.address, //场地地址
              area_id: form.field, //选择场地
              org_id: org_id, //机构/学科id
              exam_areab: form.testRoomB, //考场类B
              course_id: form.subject, //科目
              num: form.testpeople, //考场人数
              exam_time: dateTime, //起止时间
              // exam_time: form.startStop, //起止时间
              exam_len: form.duration, //时长
              remark: form.remarks, //简要备注
            },
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                setTimeout(() => {
                  this.goBack();
                }, 1500);
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                setTimeout(() => {
                  this.goBack();
                }, 1500);
              }
            })
            .catch(() => {
              this.bool = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    resetForm(form) {
      //取消
      this.goBack();
      this.$refs[form].resetFields();
    },

    addstu() {
      //添加学员
      // console.log()
      this.$router.push({
        path: "addStudent",
      });
    },
  },
};
</script>

<style scoped="scoped">
.EditArrange {
  width: 100%;
  height: 100%;
}
/* 上面部分 */
.top {
  padding-top: 25px;
  margin: 0px 24px 39px 28px;
  display: flex;
  justify-content: space-between;
}

.el-btn {
  background: #18bc9c;
  padding-left: 25px;
  background-position: 5px 7px;
  display: inline-block;
  background-image: url(../../assets/back.png);
  background-repeat: no-repeat;
  color: #ffffff;
}
.el-form {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

::v-deep .el-form-item__label {
  color: #000000;
}
.el-input {
  width: 300px;
  height: 37px !important;
}

.el-select {
  width: 300px;
}
.el-range-editor.el-input__inner {
  width: 300px;
}
/* 底部提交 取消 */
.dialog-footer {
  text-align: center;
  padding-bottom: 20px;
}
.btn:hover {
  color: #666;
  border-color: #ccc;
  background-color: #ffffff;
}
</style>
